import http from "@/utils/http";
import baseConfig from '@/utils/baseConfig';

export const sendVerifyCode = (params) => http.post('/api/SendCode/SendCode', params);


export const sendLoginCode = (params) => http.get('/login/code', params);
export const sendModifyPasswordCode = (params) => http.get('/login/modifyPasswordCode', params);
export const loginByCode = (params) => http.post('/login/code', params);

export const loginByPwd = (params) => http.post('/api/customer/Login', params);
export const modifyPassword = (params) => http.put('/login/modifyPassword', params);

export const retrievePassword = (params) => http.get('/api/customer/RetrievePassword?', params);
export const setPassword = (params) => http.post('/api/customer/setPassword', params);
export const initPersioninfo = (params) => http.post('/api/customer/initPersioninfo', params);
export const initDoc = (params) => http.post('/api/customer/initDoc', params);
export const changeavater = (params) => http.post('/api/customer/changeavater', params);
export const EditPersonInfo= (params) => http.post('/api/customer/EditPersonInfo', params);
export const getinitInfo = (params) => http.get('/api/customer/getinitInfo');
export const getmemberdata = (params) => http.get('/api/customer/getmemberdata');
export const getibs = (params) => http.get('/api/customer/getibs');
export const getrefrencelink = (params) => http.post('/api/customer/getrefrencelink',params);

export const applycopy = (params) => http.post('/api/Signal/applycopy',params);

export const getmoneyrecored = (params) => http.post('/api/Funds/getmoneyrecored',params);
export const getsubusersrecored = (params) => http.post('/api/Funds/getsubusersrecored',params);
export const GetAllsignal = (params) => http.get('/api/Signal/GetAllsignal',params);
export const GetusersignalPageList =(params)=>http.post('/api/Signal/GetusersignalPageList',params);
export const DeleteFollow =(params)=>http.post('/api/Signal/DeleteFollow',params);
export const FollowSignalLogList =(params)=>http.post('/api/Signal/FollowSignalLogList',params);
export const GetLoginList =(params)=>http.get('/api/Signal/GetLoginList',params);
export const SignalApplyAction =(params)=>http.post('/api/Signal/SignalApplyAction',params);
export const GetSignalApplyList =(params)=>http.post('/api/Signal/GetSignalApplyList',params);
export const GetUserSignalList =(params)=>http.post('/api/Signal/GetUserSignalList',params);
export const DeleteSignal =(params)=>http.post('/api/Signal/DeleteSignal',params);
export const GetVWSignalPages =(params)=>http.post('/api/Signal/GetVWSignalPages',params);
export const GetCanCopyLogins =(params)=>http.post('/api/Signal/GetCanCopyLogins',params);

export const register = (params) => http.post('/api/customer/Register', params);
export const decrypt = (params) => http.post('/api/customer/decryptuid', params);
export const active = (params) => http.post('/api/customer/Active', params);
export const getLoginType = (params) => http.post('/api/OpenAccount/GetLoginType', params);
export const newAccount = (params) => http.post('/api/OpenAccount/NewAccount', params);

export const changeCrmPwd = (params) => http.post('/api/OpenAccount/ChangeCrmPwd', params);
export const changeMt4Pwd = (params) => http.post('/api/OpenAccount/ChangeMt4Pwd', params);
export const changeLeverage = (params) => http.post('/api/OpenAccount/ChangeLeverage', params);

export const getUserLogins = (params) => http.post('/api/Funds/GetLogins', params);
export const getFreeBalanceByLogin = (params) => http.post('/api/Funds/GetBalance', params);
export const getBank = (params) => http.post('/api/Funds/GetBank', params);
export const getBankById = (params) => http.post('/api/Funds/GetBankById', params);
export const addWithdrawals = (params) => http.post('/api/Funds/Withdrawals', params);
export const transSameNameOnly = (params) => http.post('/api/Funds/TransSameNameOnly', params);
export const addTransfer = (params) => http.post('/api/Funds/Transfer', params);
export const addBank = (params) => http.post('/api/Funds/AddBank', params);
export const editBank = (params) => http.post('/api/Funds/EditBank', params);
export const getRate = (params) => http.post('/api/Funds/GetRate', params);
export const getPayMethod = (params) => http.post('/api/Funds/GetPayMethod', params);
export const getBankWire = (params) => http.post('/api/Funds/BankWire', params);
export const bankWireUsdtBtcDeposit = (params) => http.post('/api/Funds/BankWireUsdtBtc', params);
export const getBankWireInfo = (params) => http.post('/api/Funds/GetBankWireInfo', params);
export const uploadCert = (params) => http.post('/api/Funds/UploadCert', params);
export const GetBankByType = (params) => http.post('/api/Funds/GetBankByType', params);

export const onlineDeposit = (params) => http.post('/api/OnLineDeposit/DemoPay', params);

export const getHistoryByLogin = (params) => http.post('/api/report/GetHistory', params);
export const getPositionsByLogin = (params) => http.post('/api/report/GetPositions', params);
export const getCommissionByLogin = (params) => http.post('/api/report/GetCommissionByLogin', params);
export const getTotalCommission = (params) => http.post('/api/report/GetTotalCommission', params);
export const getSummary = (params) => http.post('/api/report/GetSummary', params);
export const getHome = (params) => http.post('/api/report/GetHome', params);


// 图片
export const UPLOAD_EDITAVATAR = (data) => http.upload(baseConfig.imageBase64Url, data);




